var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      {
        staticClass: "yqg-icon-collapse",
        class: _vm.rootClass,
        style: _vm.rootStyle,
      },
      _vm.$listeners
    ),
    [
      _c("span", { staticClass: "horizontal", style: _vm.horizontalStyle }),
      _vm._v(" "),
      _c("span", { staticClass: "vertical", style: _vm.verticalStyle }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }