<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-08-10 19:31:55.006 -->
<!-- @desc generated by yqg-cli@0.3.1 -->

<template>
    <div
        :class="rootClass"
        :style="rootStyle"
        class="yqg-icon-collapse"
        v-on="$listeners"
    >
        <span
            :style="horizontalStyle"
            class="horizontal"
        />
        <span
            :style="verticalStyle"
            class="vertical"
        />
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'YqgIconCollapse',

        props: {
            collapsed: {
                type: Boolean,
                default: true
            },

            size: {
                type: [String, Number],
                default: 8 // px
            },

            lineWidth: {
                type: [String, Number],
                default: 2 // px
            }
        },

        computed: {
            rootClass() {
                const {collapsed} = this;

                return {collapsed};
            },

            rootStyle() {
                const {size} = this;

                return {width: `${size}px`, height: `${size}px`};
            },

            horizontalStyle() {
                const {size, lineWidth} = this;
                const top = Math.floor((size - lineWidth) / 2);

                return {
                    top: `${top}px`,
                    width: `${size}px`,
                    height: `${lineWidth}px`
                };
            },

            verticalStyle() {
                const {size, lineWidth} = this;
                const left = Math.floor((size - lineWidth) / 2);

                return {
                    left: `${left}px`,
                    width: `${lineWidth}px`,
                    height: `${size}px`
                };
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-icon-collapse {
        position: relative;
        cursor: pointer;
        width: 12px;

        span {
            position: absolute;
            display: block;
            background-color: #333;
            transition: 0.2s ease-in-out;
        }

        .horizontal {
            left: 0;
            transform: rotate(180deg);
        }

        .vertical {
            top: 0;
            transform: rotate(-90deg);
        }

        &.collapsed {
            .horizontal {
                transform: rotate(0);
            }

            .vertical {
                transform: rotate(0);
            }
        }
    }

</style>
